import React, { useEffect, useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAdherant,
  editAdherant,
} from "../../Redux/Actions/adherantAction";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { Modal } from "react-bootstrap";

const AdherantModifier = () => {
  const foundADH = useSelector((state) => state.adherantReducer.adherant);
  const loadADH = useSelector((state) => state.adherantReducer.loadADH);

  const [adherant, setAdherant] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!loadADH) {
      if (!foundADH) {
        navigate("/Adherant");
      }
    }
  }, [foundADH, loadADH]);

  const handleChange = (e) => {
    setAdherant({ ...adherant, [e.target.name]: e.target.value });
  };
 
  const handleClick = async () => {
    // Additional validation checks

    await dispatch(editAdherant(foundADH._id, adherant));
    navigate("/Adherant");
    

  };
  const handleDelete = async () => {
    await dispatch(deleteAdherant(foundADH._id));
    navigate("/Adherant");
    handleClose();
  };
  // modal delete button
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      {/* label  1 */}
      <Form
        noValidate
        className="p-4 border rounded shadow text-center"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <h1 style={{ marginTop: "50px" }}> فضاء الفلاح</h1>
        <h2
          style={{
            marginTop: "50px",
            marginBottom: "80px",
            color: " hsla(101, 66%, 24%, 1)",
          }}
        >
          تعديل معلومات الفلاح
        </h2>
        <Form.Group as={Row} className="mb-3" style={{ marginTop: "10 0px" }}>
          <Form.Label column md={1} className="text-end order-md-2">
            رقم بطاقة التعريف الوطنية{" "}
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            
              <Form.Control
                type="tel"
                name="cin"
                pattern="[01][0-9]{7}"
                maxLength="8"
                
               placeholder={foundADH?.cin}
                onChange={(e) => handleChange(e)}
                className="text-end"
                disabled
              />
              <Form.Control.Feedback type="invalid">
                يرجى كتابة رقم بطاقة التعريف الوطنية
              </Form.Control.Feedback>
            
          </Col>
        </Form.Group>

        {/* label 2  */}
        <Form.Group as={Row} className="mb-4">
          <Form.Label column md={1} className="text-end order-md-2">
            الاسم
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Control
              onChange={(e) => handleChange(e)}
              name="name"
              type="text"
              placeholder={foundADH?.name}
              className="text-end"
            />
            <Form.Control.Feedback type="invalid">
              يجب تعمير هذه الخانة
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        {/* label 3  */}
        <Form.Group as={Row} className="mb-3">
          <Form.Label column md={1} className="text-end order-md-2">
            اللقب
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Control
              onChange={(e) => handleChange(e)}
              name="lastname"
              type="text"
              placeholder={foundADH?.lastname}
                            className="text-end"
            />
            <Form.Control.Feedback type="invalid">
              يجب تعمير هذه الخانة
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        {/* label 3 Added */}
        <Form.Group as={Row} className="mb-4">
          <Form.Label column md={1} className="text-end order-md-2">
            إسم الأب
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Control
              onChange={(e) => handleChange(e)}
              name="fathername"
              type="text"
              placeholder={foundADH?.fathername}
              className="text-end"
            />
            <Form.Control.Feedback type="invalid">
              يجب تعمير هذه الخانة
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        {/* label 4  */}

        <Form.Group as={Row} className="mb-3">
          <Form.Label column md={1} className="text-end order-md-2">
            الهاتف{" "}
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1" style={{display:"flex",flexDirection:"row"}}>
            
          <Button id="phone-addon">+216</Button>
              <Form.Control
                type="tel"
                name="phone"
                pattern="[0-9]{8}"
                maxLength="8"
                placeholder={foundADH?.phone}
                aria-describedby="phone-addon"
                onChange={(e) => handleChange(e)}
                className="text-end"
              />
              <Form.Control.Feedback type="invalid">
                يرجى كتابة ثمانية أرقام من رقم الهاتف
              </Form.Control.Feedback>
            
          </Col>
        </Form.Group>

        {/* label 5*/}

        <Form.Group as={Row} className="mb-4">
          <Form.Label column md={1} className="text-end order-md-2">
            المدينة
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Select
              name="dependency"
              className="text-end"
              value={foundADH?.dependency}
              
            >
           
            <option>ــ اختيار ــ </option>
              <option value={1} >بنقردان</option>
              <option value={2}>بني خداش</option>
              <option value={3}> جربة ميدون</option>
              <option value={4}> جربة أجيم</option>
              <option value={5}>سيدي مخلوف</option>
              <option value={6}> جربة حومة السوق</option>
              <option value={7}> جرجيس</option>
              <option value={8}>مدنين الجنوبية</option>
              <option value={9}>مدنيـن الشمالية</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              يجب اختيار خانة
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        {/* sapce 150 px */}

        {/* label 7 */}

        <Form.Group as={Row} className="mb-3">
          <Form.Label column md={1} className="text-end order-md-2">
            النيابة
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Select
              name="proxy"
              className="text-end"
              value={0}
              
            >
            
              <option value={0}>{foundADH?.proxy} </option>
            </Form.Select>
          </Col>
        </Form.Group>

        {/* sapce 150 px */}
        {/* label 8 */}
        <Row>
          <Form.Group as={Row} className="mb-3" style={{ marginTop: "150px" }}>
            <Form.Label column md={1} className="text-end order-md-2">
            ضان
            </Form.Label>
            <Col md={{ span: 3, offset: 6 }} className="order-md-1">
              <Form.Control
                onChange={(e) => handleChange(e)}
                name="mouton"
                min={0}
                type="number"
                placeholder={foundADH?.mouton}
                className="text-end"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column md={1} className="text-end order-md-2">
              الماعز
            </Form.Label>
            <Col md={{ span: 3, offset: 6 }} className="order-md-1">
              <Form.Control
                name="chevre"
                onChange={(e) => handleChange(e)}
                min={0}
                type="number"
                placeholder={foundADH?.chevre}
                className="text-end"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column md={1} className="text-end order-md-2">
            خيول
            </Form.Label>
            <Col md={{ span: 3, offset: 6 }} className="order-md-1">
              <Form.Control
                name="cheval"
                onChange={(e) => handleChange(e)}
                min={0}
                type="number"
                placeholder={foundADH?.cheval}
                className="text-end"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column md={1} className="text-end order-md-2">
            ابل
            </Form.Label>
            <Col md={{ span: 3, offset: 6 }} className="order-md-1">
              <Form.Control
                name="chameau"
                onChange={(e) => handleChange(e)}
                min={0}
                type="number"
                placeholder={foundADH?.chameau}
                className="text-end"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column md={1} className="text-end order-md-2">
            ابقار
            </Form.Label>
            <Col md={{ span: 3, offset: 6 }} className="order-md-1">
              <Form.Control
                name="vache"
                onChange={(e) => handleChange(e)}
                min={0}
                type="number"
                placeholder={foundADH?.vache}
                className="text-end"
              />
            </Col>
          </Form.Group>
        </Row>

        <div style={{ marginTop: "50px" }}>
          <Button variant="outline-danger" onClick={handleShow} >
            {" "} 
             حذف المشترك
          </Button>
          <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title dir="rtl" >تأكيد حذف المشترك</Modal.Title>
          </Modal.Header>
          <Modal.Body dir="rtl">هل تريد حقا حذف  المشترك...؟</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              الغاء
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              تأكيد الحذف
            </Button>
          </Modal.Footer>
        </Modal>






          <Button
            style={{ marginLeft: "40px" }}
            variant="outline-success"
            onClick={async () => handleClick()}
          >
            تعديل الحساب
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AdherantModifier;
