import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import './Navb.css';
//import form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import {  useNavigate } from "react-router-dom";
import { getappros, logout } from "../../Redux/Actions/userAction";

const Navb = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.userReducer.user);
  
  const handleLogout = async () => {
    if (user) {
      dispatch(logout());
    }
    navigate("/login");
  };
  // const butonloginlogout = {
  //   backgroundColor: user !== null ? '#FF1C49' : '#1157A2',
  //   color: 'white',
  //   size: 'sm',
  // };
const handleSms = async () => { 
  dispatch(getappros(user.dependency))
   navigate("/SmsType")
}
const handleRsms = async () => {
  dispatch(getappros(user.dependency))
  navigate("/ConfirmationSmsUn")
}
const handleRsmsss = async () => {
  dispatch(getappros(user.dependency))
  navigate("/EtatSMS")
}
  return (
    <div>
      <Navbar expand="lg" className="bg-body-tertiary border ">
        <Container fluid>
          <Navbar.Toggle aria-controls="navbarScroll" />

          <Navbar.Collapse id="navbarScroll">
            
            <Nav
              className=" me-auto my-1 my-lg-3 "
              style={{ maxHeight: "110px" }}
              navbarScroll
            >

            {user?.typeofaccount == "Admin" ?   
            <Nav.Link   onClick={() => navigate("/register")}>

            <Button   className="btn-primaryi"  >تسجيل</Button>

            </Nav.Link>: null}
            <Nav.Link   onClick={() => navigate("/login")}>
            <Button variant="outline-secondary"  className="custom-button"  onClick={handleLogout}   >
            {user != null ? "تسجيل الخروج" : "دخول"}
          </Button>{' '}
            </Nav.Link>
              

            {user?.typeofaccount == "Admin" || user?.typeofaccount=="Unionlocal" ?  <Nav.Link onClick={() => handleRsmsss()} style={{ marginLeft: "100px" }}>متابعة الارساليات</Nav.Link>: null}

            {user?.typeofaccount == "Admin" || user?.typeofaccount=="Unionlocal" || user?.typeofaccount=="Approvisionnement" ?  <Nav.Link onClick={() => handleRsms()} style={{ marginLeft: "100px" }}>تأكيد الاستلام</Nav.Link>: null}

              {user && user?.typeofaccount!="Approvisionnement"? <Nav.Link onClick={() => navigate("/Statistics")}>الإحصائيات</Nav.Link>: null}
              {user?.typeofaccount == "Admin" || user?.typeofaccount=="Unionlocal"? <Nav.Link onClick={() =>handleSms()}>فضاء الأرساليات</Nav.Link>: null}
              {user?.typeofaccount == "Admin" || user?.typeofaccount=="Unionlocal"? (
                <Nav.Link onClick={() => navigate("/Approvisionnement")}>
                  فضاء النيابات
                </Nav.Link>
              ) : null}
              {user?.typeofaccount == "Admin" || user?.typeofaccount=="Unionlocal"?  <Nav.Link onClick={() => navigate("/Adherant")}>فضاء الفلاحين</Nav.Link>: null}
              <Nav.Link onClick={() => navigate("/")}>الرئيسية</Nav.Link>
              
            </Nav>
          </Navbar.Collapse>
          <Navbar.Brand style={{color:"#0061FF"}} >{user?.name}</Navbar.Brand>
          <Nav.Link onClick={() => navigate("/")} style={{ color: "green" }}>
          <h1 style={{ fontFamily: "'Montserrat', sans-serif" }}>UTAP</h1>
        </Nav.Link>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navb;
