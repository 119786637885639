


import axios from 'axios'
import React, { useState } from 'react'

const Count = (props) => {
    const [count,setCount]=useState(0)
     axios.post("/api/Adherant/countADH",{proxy:props.proxy}).then(result=>setCount(result.data.count)).catch(error=>console.log(error))
    
    
  return (
    <div>{count}</div>
  )
}

export default Count