// Register.js
import React, { useState } from 'react';
import { Container, Form, Button, Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './Register.css'; // Import the external CSS file for styling
import { useDispatch } from 'react-redux';
import { register } from '../../Redux/Actions/userAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Register = () => {
  const date=new Date()
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [accountType, setAccountType] = useState('');
  const [newUser, setNewUser]=useState({date:date})
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State for password visibility

  const handleChange=(e)=>{
    setNewUser({...newUser,[e.target.name]:e.target.value})
  }
  const handleSignup =async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (password !== confirmPassword) {
      setPasswordError('كلمة المرور غير مطابقة!');
      return;
    }

    // Reset password error when they match
    setPasswordError('');
    await dispatch(register(newUser))
    navigate("/")
    
  };
//  Eye sH
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return (
    <Container className="container-signup">
      <div className="signup-container">
        <h2 className="signup-title">انشاء حساب جديد</h2>
        <Form>
          <Form.Group controlId="formBasicName" className="form-group">
            <Form.Control
              type="text"
              name='name'
              placeholder="الاسم"
              
              onChange={(e) => handleChange(e)}
              className="form-control"
              dir="rtl"
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail" className="form-group">
            <Form.Control
              type="email"
              name='email'
              placeholder="البريد الإلكتروني"
              
              onChange={(e) => (handleChange(e) )}
              className="form-control"
              dir="rtl"
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword" className="form-group password-group">
          <Form.Control
            type={showPassword ? "text" : "password"}
            name='password'
            placeholder="كلمة المرور"
            onChange={(e) => (handleChange(e), setPassword(e.target.value))}
            className="form-control"
            dir="rtl"
            isInvalid={passwordError.length > 0}
          />
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            className="password-toggle-icon"
            onClick={togglePasswordVisibility}
          />
          <Form.Control.Feedback type="invalid">
            {passwordError}
          </Form.Control.Feedback>
        </Form.Group>
        

          <Form.Group controlId="formBasicConfirmPassword" className="form-group">
            <Form.Control
            type={showPassword ? "text" : "password"}
              placeholder="تأكيد كلمة المرور"
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="form-control"
              dir="rtl"
              isInvalid={passwordError.length > 0 && confirmPassword.length > 0}
            />
            
            <Form.Control.Feedback type="invalid">
            كلمة المرور غير مطابقة!
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formBasicAccountType" className="form-group">
            <Form.Select
              placeholder='اختر نوع الحساب'
              name='typeofaccount'
              
              onChange={(e) => handleChange(e)}
              className="form-control"
              dir="rtl"
            >
              <option value="">اختر نوع الحساب</option>
              <option value="Admin">أدمين</option>
              <option value="Unionlocal"> اتحاد محلي</option>
              <option value="Control"> سلط جهوية</option>
              <option value="delegation"> معتمدية</option>


              {/* Add more options as needed */}
            </Form.Select>
          </Form.Group>
          {newUser?.typeofaccount=="Unionlocal" || newUser?.typeofaccount=="delegation"?<Form.Group as={Row} className="mb-4">
        <Form.Label column md={1} className="text-end order-md-2">
          المعتمدية
        </Form.Label>
        <Col md={{ span: 6, offset: 3 }} className="order-md-1">
          <Form.Select className="text-end" placeholder="ــ اختيار ــ" name="dependency" onChange={(e)=>handleChange(e)}>
            <option>ــ اختيار ــ </option>
            <option value="1">بنقردان</option>
            <option value="2">بني خداش</option>
            <option value="3"> جربة ميدون</option>
            <option value="4"> جربة أجيم</option>
            <option value="5">سيدي مخلوف</option>
            <option value="6"> جربة حومة السوق</option>
            <option value="7"> جرجيس</option>
            <option value="8">مدنين الجنوبية</option>
            <option value="9">مدنيـن الشمالية</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            يجب اختيار خانة
          </Form.Control.Feedback>
        </Col>
      </Form.Group>:null}

          <Button
            variant="primary"
            type="submit"
            block
            onClick={handleSignup}
            className="btn-primary"
            style={{marginBottom: '100px'}}
          >
            إنشاء حساب
          </Button>
        </Form>

        
      </div>
    </Container>
  );
};

export default Register;
