import React, { useEffect, useState } from 'react'
import { Bar, Pie} from 'react-chartjs-2';
import {CategoryScale} from 'chart.js'; 
import Chart from 'chart.js/auto';

import './UnStat.css';
import axios from 'axios'
import { useSelector } from 'react-redux';


const UnStat = () => {

  Chart.register(CategoryScale);
  const user=useSelector(state=>state.userReducer.user)
    const [adherantsData, setAdherantsData] = useState([]);
    const [totaladhData, setTotalADHData] = useState();
    const [approData, setApproData] = useState([]);
    const [totalapproData, setTotalApproData] = useState();
    const [totalorgeData, setTotalOrgeData] = useState([]);
    const [totaloData, setTotalOData] = useState();
    const [totalcedraData, setTotalCedraData] = useState([]);
    const [totalcData, setTotalCData] = useState();
    const [orgemoazaaData, setOrgeMoazaaData] = useState([]);
    const [cedramoazaaData, setCedraMoazaaData] = useState([]);
    const [orgeresteData, setOrgeResteData] = useState([]);
    const [cedrresteData, setCedraResteData] = useState([]);


    useEffect(() => {
      fetchDataAdherant();
      fetchDataAppro()
      fetchDataTotalOrge()
      fetchDataTolalCedra()
      fetchDataResteOrgeAndCedra()
      fetchDataMoazaaOrgeAndCedra()
      
    }, []);

    const fetchDataAdherant = async () => {
      try {
        
    
        let response=await axios.post('/api/Adherant/dataAdherant',{dependency:user?.dependency});
      
        let backendData = response?.data?.adherants
        let totalAppro = response?.data?.totalCount; // Récupérer le totalCount et le retirer des données mensuelles
        
        setAdherantsData( backendData);
        setTotalADHData(totalAppro)
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

  let months = adherantsData?.map(data => data?.month);
  let counts = adherantsData?.map(data => data?.count);
  
  
 

  const dataadherant = {
      labels:[...months, 'مجموع الفلاحة'],
      datasets: [
        {
            label: 'عدد الفلاحة',
            backgroundColor: 'rgba(75,192,192,1)',
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 2,
            data: [...counts, totaladhData] // Ajouter le totalCount à la liste des counts
        }
    ]
  };
// *******************************************************************************************************
    const fetchDataAppro = async () => {
      try {
        
    
        let response=await axios.post('/api/user/dataAppro',{dependency:user?.dependency});
      
        let backendData = response?.data?.Appro
        let totalAppro = response?.data?.totalCount; 
        
        setApproData( backendData);
        setTotalApproData(totalAppro)
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

  let months1 = approData?.map(data => data?.month);
  let counts1 = approData?.map(data => data?.count);
  
  
 

  const dataappro = {
      labels:[...months1, 'مجموع النيابات'],
      datasets: [
        {
            label: 'عدد النيابات',
            backgroundColor: 'rgba(75,192,192,1)',
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 2,
            data: [...counts1, totalapproData] // Ajouter le totalCount à la liste des counts
        }
    ]
  };
// *******************************************************************************************************
     const fetchDataTotalOrge = async () => {
      try {
        
    
        let response=await axios.post('/api/Adherant/dataOrgeTotal',{dependency:user?.dependency});
      
        let backendData = response?.data?.adherants
        let totalOrge = response?.data?.totalOrge; 
        
        setTotalOrgeData( backendData);
        setTotalOData(totalOrge)
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

  let months2 = totalorgeData?.map(data => data?.month);
  let Cota = totalorgeData?.map(data => data?.Cota);

  const datatotalorge = {
    labels:[...months2, 'كمية الشعير الاجمالية'],
    datasets: [
      {
          label: 'العدد الجملي للشعير',
          backgroundColor: 'rgba(75,192,192,1)',
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 2,
          data: [...Cota, totaloData] // Ajouter le totalCount à la liste des counts
      }
  ]
  };

  // *******************************************************************************************************
     const fetchDataTolalCedra = async () => {
     try {
        
    
  let response=await axios.post('/api/Adherant/dataCedraTotal',{dependency:user?.dependency});
      
  let backendData = response?.data?.adherants
  let totalCedra = response?.data?.totalCedra; 
  
  setTotalCedraData( backendData);
  setTotalCData(totalCedra)
} catch (error) {
    console.error('Error fetching data:', error);
}
};

let months3 = totalcedraData?.map(data => data?.month);
let Cota1 = totalcedraData?.map(data => data?.Cota);

const datatotalcedra = {
labels:[...months3, 'كمية السداري الاجمالية'],
datasets: [
{
    label:'العدد الجملي للسداري',
    backgroundColor: 'rgba(75,192,192,1)',
    borderColor: 'rgba(0,0,0,1)',
    borderWidth: 2,
    data: [...Cota1, totalcData] // Ajouter le totalCount à la liste des counts
}
]
  };

  // *******************************************************************************************************
  const fetchDataResteOrgeAndCedra = async () => {
    try {
        // Fetching restO data
        let responseOrge = await axios.post('/api/user/restApproOrge', {dependency: user?.dependency});
        let backendDataOrge = responseOrge?.data?.restO;
        setOrgeResteData(backendDataOrge);

        // Fetching restC data
        let responseCedra = await axios.post('/api/user/restApproCedra', {dependency: user?.dependency});
        let backendDataCedra = responseCedra?.data?.restC;
        setCedraResteData(backendDataCedra);

      } catch (error) {
        console.error('Error fetching data:', error);
    }
  }
        // Prepare combined data for pie chart
        const dataResteOrgeCedra = {
            labels: ['الكمية المتبقية للشعير', 'الكمية الموزعة للشعير'],
            datasets: [
                {
                    label: 'الكمية المتبقية و الموزعة',
                    backgroundColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
                    borderColor: 'rgba(0, 0, 0, 1)',
                    borderWidth: 2,
                    data: [orgeresteData, orgemoazaaData]
                }
            ]
        };
    
        const fetchDataMoazaaOrgeAndCedra = async () => {
          try {
              // Fetching restO data
              let responseOrge = await axios.post('/api/user/moazaaApproOrge', {dependency: user?.dependency});
              let backendDataOrge = responseOrge?.data?.MoazaaO;
              setOrgeMoazaaData(backendDataOrge);
      
              // Fetching restC data
              let responseCedra = await axios.post('/api/user/MoazaaApproCedra', {dependency: user?.dependency});
              let backendDataCedra = responseCedra?.data?.MoazaaC;
              setCedraMoazaaData(backendDataCedra);
      
            } catch (error) {
              console.error('Error fetching data:', error);
          }
        }
              // Prepare combined data for pie chart
              const dataMoazaaOrgeCedra = {
                  labels: ['الكمية المتبقية للسداري', 'الكمية الموزعة للسداري'],
                  datasets: [
                      {
                          label: 'الكمية المتبقية و الموزعة',
                          backgroundColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
                          borderColor: 'rgba(0, 0, 0, 1)',
                          borderWidth: 2,
                          data: [cedrresteData, cedramoazaaData]
                      }
                  ]
              };
          

    
    const dataSMS = {
      labels: [' عدد الارساليات المبعوثة ',' عدد الارساليات المتبقية'],
      datasets: [
          {
              label: 'عدد الارساليات',
              backgroundColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
              borderColor: 'rgba(0, 0, 0, 1)',
              borderWidth: 2,
              data: [user?.numSMS?user.numSMS:0,user?.numSMS?500000-user?.numSMS:500000]
          }
      ]
  };

    

    return (
      <div className="container50">
      <div className="item">
          <h2>عدد الفلاحين الاجمالي و حسب الشهر</h2>
          <Bar data={dataadherant}  />
      </div>
      <div className="item">
          <h2>عدد النيابات الاجمالي و حسب الشهر</h2>
          <Bar data={dataappro}  />
      </div>
      <div className="item">
          <h2>كمية الشعير الاجمالي و حسب الشهر</h2>
          <Bar data={datatotalorge}  />
      </div>
      <div className="item">
          <h2>كمية السداري الاجمالي و حسب الشهر</h2>
          <Bar data={datatotalcedra}  />
      </div>
      <div className="item">
          <h2>الكمية المتبقية و الموزعة للشعير</h2>
          <Pie data={dataResteOrgeCedra}  />
      </div>
      <div className="item">
          <h2>الكمية المتبقية و الموزعة للسداري</h2>
          <Pie data={dataMoazaaOrgeCedra}  />
      </div>
      <div className="item">
          <h2>عدد الارساليات المبعوثة و المتبقية</h2>
          <Pie data={dataSMS}  />
      </div>
  </div>









);
};

export default UnStat;