import React, { useEffect, useState } from "react";
import $ from "jquery";
import "jquery-ui/ui/widgets/autocomplete"; // Include the specific jQuery UI module you need
import "./Adherant.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Include Bootstrap styles
import { Link, useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from "react-redux";
import { clearAdherant, findAdherant } from "../../Redux/Actions/adherantAction";
import { CLEAR_ADHERANT } from "../../Redux/ActionType/actionTypes";
import { getappros } from "../../Redux/Actions/userAction";

const Adherant = () => {
  const user=useSelector(state=>state.userReducer.user)
  const dispatch = useDispatch();
  const [cin, setCin] = useState({});
  const navigate=useNavigate()

  const handleChange=(e)=>{
    setCin({...cin,[e.target.name]:e.target.value})
  }
  useEffect(() => {
    // Your jQuery code here
    $(".veen .rgstr-btn button").click(function () {
      $(".veen .wrapper-f").addClass("move");
      $(".body").css("background", "linear-gradient(90deg, hsla(270, 94%, 25%, 1) 0%, hsla(158, 94%, 49%, 1) 100%)");
      $(".veen .login-btn button").removeClass("active");
      $(this).addClass("active");
    });

    $(".veen .login-btn button").click(function () {
      $(".veen .wrapper-f").removeClass("move");
      $(".body").css("background", "linear-gradient(90deg, hsla(158, 94%, 49%, 1) 0%, hsla(270, 94%, 25%, 1) 100%)");
      $(".veen .rgstr-btn button").removeClass("active");
      $(this).addClass("active");
    });
  }, []);

 useEffect(() => {
   dispatch(clearAdherant());
 },)
 const dependency=user.dependency

  const handleClick =async () => {
   dispatch(findAdherant(cin))
  navigate ('/AdherantModifier')
  };
  const handleajout=async()=>{
    dispatch(getappros(dependency))
  }
  

  return (
    <div className="body">
      <div className="veen">
        <div className="login-btn splits">
          <p>تريد إضافة فلاح ؟</p>
          <button className="active">إضافة</button>
        </div>
        <div className="rgstr-btn splits">
          <p>تريد تعديل حساب فلاح ؟</p>
          <button>تعديل</button>
        </div>
        <div className="wrapper-f">
          <form id="login" tabIndex="500">
            <h3>إضافة فلاح </h3>
            <div className="submit">
              <Link to="/AdherantAjouter">
                <button className="dark-d" style={{ marginTop: '50px' }} onClick={handleajout}>إضافة</button>
              </Link>
            </div>
          </form>
          <form id="register" tabIndex="502">
            <h3>تعديل حساب الفلاح</h3>
            <Form.Control
              type="search"
              placeholder="إبحث هنا"
              className="me-2 text-end"
              aria-label="Search"
              name="cin"
              onChange={(e)=>handleChange(e)}
            />
           
              
                <button className="dark-d"  onClick={async () => handleClick()} >تعديل</button>
              
            
          </form>
        </div>
      </div>
    </div>
  );      
};

export default Adherant;
