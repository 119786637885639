import React, { useState } from "react";
import { Form } from "react-bootstrap";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";



const SmsType = () => {
  const [validated, setValidated] = useState(false);
  const user=useSelector(state=>state.userReducer.user)

  return (
    <div style={{ marginTop: "50px" }}>
      <Form
        noValidate
        validated={validated}
        className="p-4  rounded  text-center"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <h1 style={{ marginBottom: "20px" }}>فضاء الأرساليات</h1>

        <div className="wrapper container">
          <div className="row justify-content-center">
            {user?.typeofaccount === "Admin" || user?.typeofaccount === "Unionlocal" || user?.typeofaccount=="delegation" || user?.typeofaccount=="Control"? <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              
              <Link className="dashboard-stat red" to="/SuppGestionSms" >
                <div className="visual">
                  <i className="fa fa-usd"></i>
                </div>
                <div className="details">
                  <div className="desc">ارساليات تكميلية </div>
                </div>
              </Link>
            </div>:null}
            {user?.typeofaccount === "Admin" || user?.typeofaccount === "Unionlocal" || user?.typeofaccount=="delegation" || user?.typeofaccount=="Control"?<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <Link className="dashboard-stat blue" to="/GestionSms">
                <div className="visual">
                  <i className="fa fa-bar-chart-o"></i>
                </div>
                <div className="details">
                  <div className="desc">  الارساليات </div>
                </div>
              </Link>
            </div>:null}
            
          
          </div>
        </div>

        <div>
        
      </div>
        
      </Form>
    </div>
  );
};

export default SmsType;
