import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {  register } from "../../Redux/Actions/userAction";
const ApprovisionnementAjouter = () => {
  const user=useSelector(state=>state.userReducer.user)
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const [newUserr,setNewUserr] = useState({typeofaccount:"Approvisionnement",dependency:user.dependency})
  const [validated, setValidated] = useState(false);
  const handleChange=(e)=>{
    setNewUserr({...newUserr,[e.target.name]:e.target.value})
  }

  const handleSubmit = async(event) => {
      
    await dispatch(register(newUserr))
    navigate("/Approvisionnement") 
  };
  
  return (
    <div>
      <Form
        noValidate
        validated={validated}
       
        className="p-4 border rounded shadow text-center"
        style={{ backgroundColor: "#f8f9fa" }}
      >
      <h1 style={{ marginTop: "50px", color:" hsla(82, 96%, 57%, 1)" }}> فضاء النيابات</h1>
      <br />
      <h2
        style={{
          marginTop: "50px",
          marginBottom: "80px",
          color:"hsla(217, 100%, 50%, 1)",
        }}
      >
          اضافة تاجر الاعلاف
      </h2>
        {/* label  1 */}

        <Form.Group style={{ marginTop: "120px" }} as={Row} className="mb-4">
          <Form.Label column md={1} className="text-end order-md-2">
          بريد إلكتروني 
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Control
            name="email"
              
              type="text"
              placeholder="بريد إلكتروني"
              className="text-end"
              onChange={(e)=>handleChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              يجب تعمير هذه الخانة
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        {/* label 2  */}
       

        {/* label 3  */}

        <Form.Group as={Row} className="mb-4">
          <Form.Label column md={1} className="text-end order-md-2">
            اسم المستخدم
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Control
              
              name="name"
              type="text"
              placeholder="اسم المستخدم"
              className="text-end"
              onChange={(e)=>handleChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              يجب تعمير هذه الخانة
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        {/* label 4  */}
        <Form.Group as={Row} className="mb-4">
          <Form.Label column md={1} className="text-end order-md-2">
            كلمة السر
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Control
              
              type="text"
              name="password"
              placeholder="كلمة السر"
              className="text-end"
              onChange={(e)=>handleChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              يجب تعمير هذه الخانة
            </Form.Control.Feedback>
          </Col>
        
        </Form.Group>
        {/* label 5 */}
        
        <Form.Group as={Row} className="mb-4">
        <Form.Label column md={1} className="text-end order-md-2">
          المعتمدية
        </Form.Label>
        <Col md={{ span: 3, offset: 6 }} className="order-md-1">
          <Form.Select className="text-end" placeholder="ــ اختيار ــ" value={user.dependency}   >
            <option>ــ اختيار ــ </option>
            <option value="1">بنقردان</option>
            <option value="2">بني خداش</option>
            <option value="3"> جربة ميدون</option>
            <option value="4"> جربة أجيم</option>
            <option value="5">سيدي مخلوف</option>
            <option value="6"> جربة حومة السوق</option>
            <option value="7"> جرجيس</option>
            <option value="8">مدنين الجنوبية</option>
            <option value="9">مدنيـن الشمالية</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            يجب اختيار خانة
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

        {/* sapce 150 px */}

        {/* label 5*/}

        <Form.Group as={Row} style={{ marginTop: "100px" }}>
          <Form.Label column md={1} className="text-end order-md-2">
            عدد شاحنات الشعير
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Control
              
              name="numberofcamionO"
              min={0}
                type="number"
              pattern="[0-9]{0-4}"
              placeholder="عدد شاحنات الشعير  "
              className="text-end"
              onChange={(e)=>handleChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              يجب تعمير هذه الخانة
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        {/* label 7 */}

        <Form.Group as={Row} className="mt-3">
          <Form.Label column md={1} className="text-end order-md-2">
            عدد شاحنات السداري
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Control
              
              name="numberofcamionS"
              min={0}
                type="number"
              pattern="[0-9]{0-4}"
              placeholder="عدد شاحنات السداري"
              className="text-end"
              onChange={(e)=>handleChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              يجب تعمير هذه الخانة
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        {/* sapce 150 px */}
        {/* label 8 */}

        <Form.Group as={Row} className="mb-4 mt-4">
          <Col md={{ span: 3, offset: 6 }} className="order-md-1"></Col>
        </Form.Group>
        <Button variant="outline-primary" onClick={()=>handleSubmit()} >
          {" "}
          إضافة
        </Button>
      </Form>
    </div>
  );
};

export default ApprovisionnementAjouter;
