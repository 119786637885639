import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../Redux/Actions/userAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [validated, setValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  var foundUser = useSelector((state) => state.userReducer.user);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);
    await dispatch(login(user));
  };

  useEffect(() => {
    if (foundUser != null) {
      navigate("/");
    }
  }, [foundUser, navigate]);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container className="container-login">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="login-container">
          <h2 className="login-title"> ! مرحبًا بعودتك </h2>
          <Form.Group controlId="formBasicEmail" className="form-group">
            <Form.Control
              type="text"
              name="email"
              placeholder="ادخل بريد المستخدم"
              onChange={handleChange}
              className="form-control"
              dir="rtl"
              required
            />
            <Form.Control.Feedback type="invalid">
              يجب تعمير هذه الخانة
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formBasicPassword" className="form-group">
            <div className="password-input-container">
              <Form.Control
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="كلمة المرور"
                onChange={handleChange}
                className="form-control"
                dir="rtl"
                required
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="password-toggle-icon"
                onClick={togglePasswordVisibility}
              />
            </div>
            <Form.Control.Feedback type="invalid">
              يجب تعمير هذه الخانة
            </Form.Control.Feedback>
          </Form.Group>

          <Button
            type="submit"
            variant="secondary"
            className="btn-primary"
          >
            تسجيل الدخول
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default Login;
