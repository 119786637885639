import React, { useEffect, useState } from "react";
import $ from "jquery";
import "jquery-ui/ui/widgets/autocomplete"; // Include the specific jQuery UI module you need
import "./ConfirmSmsStat.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Include Bootstrap styles
import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";


const ConfirmSmsStat = () => {
const user = useSelector((state) => state.userReducer.user);
const dispatch = useDispatch();
const [cin, setCin] = useState({});
const navigate = useNavigate();

const handleChange = (e) => {
    setCin({ ...cin, [e.target.name]: e.target.value });
};
useEffect(() => {
    // Your jQuery code here
    $(".veen .rgstr-btn button").click(function () {
    $(".veen .wrapper-fx").addClass("move");
    $(".body").css(
        "background",
        "linear-gradient(90deg, hsla(207, 41%, 27%, 1) 0%, hsla(191, 28%, 23%, 1) 50%,hsla(205, 46%, 10%, 1) 100%)"
    );
    $(".veen .login-btn button").removeClass("active");
    $(this).addClass("active");
    });

    $(".veen .login-btn button").click(function () {
    $(".veen .wrapper-fx").removeClass("move");
    $(".body").css(
        "background",
        "linear-gradient(90deg,  hsla(205, 46%, 10%, 1) 100%),hsla(191, 28%, 23%, 1) 50%, hsla(207, 41%, 27%, 1) 100%)"
    );
    $(".veen .rgstr-btn button").removeClass("active");
    $(this).addClass("active");
    });
}, []);



return (
    <div className="body-f">
    <div className="veen">
        <div className="login-btn splits">
        <p>احصائيات تأكيد رسائل الشعير </p>
        <button className="active">اختيار</button>
        </div>
        <div className="rgstr-btn splits">
        <p>احصائيات تأكيد رسائل السداري </p>
        <button>اختيار</button>
        </div>
        <div className="wrapper-fx">
        <form id="login" tabIndex="500">
            <h3>احصائيات رسائل شعير </h3>
            <div className="submit">
            <Link to="/FarmerOrgeStat">
                <button
                className="dark-d"
                style={{ marginTop: "50px" }}
                
                >
                تأكيد
                </button>
            </Link>
            </div>
        </form>
        <form id="register" tabIndex="502">
            <h3>احصائيات رسائل سداري</h3>
            <Link to="/FarmerCedraStat">
            <button
                className="dark-d"
                style={{ marginTop: "50px" }}
                
            >
                تأكيد
            </button>
            </Link>
        </form>
        </div>
    </div>
    </div>
);
};

export default ConfirmSmsStat;
