import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./Statistics.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";



const Statistics = () => {
  const [validated, setValidated] = useState(false);
  const user=useSelector(state=>state.userReducer.user)

  return (
    <div style={{ marginTop: "50px" }}>
      <Form
        noValidate
        validated={validated}
        className="p-4  rounded  text-center"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <h1 style={{ marginBottom: "50px" }}>فضاء الأحصائيات</h1>

        <div className="wrapper container">
          <div className="row justify-content-center">
            {user?.typeofaccount === "Admin" || user?.typeofaccount === "Unionlocal" || user?.typeofaccount=="delegation" || user?.typeofaccount=="Control"? <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <Link className="dashboard-stat red" to="/FarmerStat">
                <div className="visual">
                  <i className="fa fa-usd"></i>
                </div>
                <div className="details">
                  <div className="desc">احصائيات الفلاحة</div>
                </div>
              </Link>
            </div>:null}
            {user?.typeofaccount === "Admin" || user?.typeofaccount === "Unionlocal" || user?.typeofaccount=="delegation" || user?.typeofaccount=="Control"?<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <Link className="dashboard-stat blue" to="/ApproStat">
                <div className="visual">
                  <i className="fa fa-bar-chart-o"></i>
                </div>
                <div className="details">
                  <div className="desc">احصائيات النيابات</div>
                </div>
              </Link>
            </div>:null}
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <Link className="dashboard-stat hoki" to="/ConfirmSmsStat">
                <div className="visual">
                  <i className="fa fa-credit-card"></i>
                </div>
                <div className="details">
                  <div className="desc">احصائيات تأكيد الاستلام</div>
                </div>
              </Link>
            </div>
            {user?.typeofaccount === "Admin" || user?.typeofaccount === "Unionlocal" || user?.typeofaccount=="delegation" ?<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <Link className="dashboard-stat purple" to="/UnStat">
              <div className="visual">
                <i className="fa fa-bar-chart-o"></i>
              </div>
              <div className="details">
                <div className="desc">احصائيات الاتحاد المحلي</div>
              </div>
            </Link>
          </div>:null}
          </div>
        </div>

        <div>
        
      </div>
        
      </Form>
    </div>
  );
};

export default Statistics;
