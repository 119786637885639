import React from 'react';
import './Error.css';
import Button from 'react-bootstrap/Button';

const Error = () => {
  return (
    <div style={{marginBottom:'160px', marginTop:'120px'}} >
      <h1>حدث خطأ في الصفحة #404 </h1>
      
      <section className="error-container">
        <span><span>4</span></span>
        <span>0</span>
        <span><span>4</span></span>
      </section>
      <div className="link-container">
        <Button variant="outline-info">الرئيسية</Button>
      </div>
    </div>
  );
};

export default Error;
