import { toast } from "react-toastify"
import {  CLEAR_APPRO, CURRENT_USER, DELETE_APPRO, EDIT_APPRO, FAIL_USER, FIND_APPRO, GET_APPROS, GET_APPROSTATISTIC, GET_UNIONSTATISTIC, LOAD_USER, LOGIN_USER, LOGOUT_USER, REGISTER_USER } from "../ActionType/actionTypes"

const initialState ={
    user:null,
    loadUser:false,
    errors:null,
    isAuth:false,
    foundAppro:null,
    approlist:null,
    statAppro:null,
    statUnion:null
}


// pure function
const userReducer=( state=initialState,{type,payload}) =>{
    
    switch (type) {
        case LOAD_USER:
            return{...state, loadUser : true}
        case LOGIN_USER:
            localStorage.setItem('token',payload.token)
            toast(payload.msg)
            return{...state,loadUser:false, user:payload.foundUser,isAuth:true }
        case REGISTER_USER:
            toast(payload.msg)
            return{...state,loadUser:false,isAuth:true }
        case LOGOUT_USER:
            toast('تم تسجيل الخروج بنجاح')
            localStorage.removeItem('token')
            return{...state,loadUser:false,isAuth:false,user:null,errors:[]}
        case FAIL_USER:
            toast(payload.data)
            return{...state,loadUser:false,errors:payload}

        case CURRENT_USER:
            return{...state,user:payload,isAuth:true}
        case FIND_APPRO:
            toast(payload.msg)
            return{...state,foundAppro:payload.foundUser,loadUser:false}
        case EDIT_APPRO:
            toast(payload.msg)
            return{...state,foundAppro:null,loadUser:false}
        case DELETE_APPRO:
            toast(payload.msg)
            return{...state,foundAppro:null,loadUser:false}
        case CLEAR_APPRO:
            return{...state,foundAppro:null}
        case GET_APPROS:
            return{...state,loadUser:false,approlist:payload.Appros.filter(el=>el.dependency==state.user.dependency)}
        case GET_APPROSTATISTIC:
            return{...state,loadUser:false,statAppro:payload.Appros}
        case GET_UNIONSTATISTIC:
            return{...state,loadUser:false,statUnion:payload.UnionLocals}
        
        default:
           return state
    } 
    }
    
    export default userReducer