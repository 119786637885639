import React, { useEffect } from 'react';

const Loading = () => {
    useEffect(() => {
        // Fakes the loading setting a timeout
        const timeout = setTimeout(() => {
          document.body.classList.add('loaded');
        }, 3500);
    
        // Cleanup function to clear the timeout if the component unmounts before the timeout completes
        return () => clearTimeout(timeout);
      }, []);
  return (
    <div>
      <div id="loader-wrapper">
        <div id="loader"></div>

        <div className="loader-section section-left"></div>
        <div className="loader-section section-right"></div>
      </div>

      
      </div>
    
  );
};

export default Loading;