import React, { useEffect, useRef } from "react";
import { Carousel } from "react-bootstrap";
import "./Home.css"; // Import your custom styles
import $ from "jquery";
import "jquery-countto";
import sliderimg from '../img/sliderimg1.png';




const Home = () => {
  const counterRef = useRef();

  useEffect(() => {
    // custom formatting example
    $(".count-number", counterRef.current).data("countToOptions", {
      formatter: function (value, options) {
        return value
          .toFixed(options.decimals)
          .replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
      },
    });

    // start all the timers
    $(".timer", counterRef.current).each(count);

    function count(options) {
      var $this = $(this);
      options = $.extend({}, options || {}, $this.data("countToOptions") || {});
      $this.countTo(options);
    }

    // Cleanup function to clear interval on component unmount
    return () => {
      $(".timer", counterRef.current).each(function () {
        var data = $(this).data("countTo");
        if (data && data.interval) {
          clearInterval(data.interval);
        }
      });
    };
  }, []); // Run the effect only once on mount

  return (
    <div className="carousel-container">
      <Carousel>
        <Carousel.Item>
          <div className="overlay"></div>
          <img
            style={{ height: "800px" }}
            className="d-block w-100"
            src={sliderimg}
            alt="Utapeimg1"
          />

          <Carousel.Caption>
            <h3>الزراعة هي الحل لتعزيز سيادتنا وتنمية اقتصادنا</h3>
          </Carousel.Caption>
        </Carousel.Item>
       
      </Carousel>

      {/* *****************Number Counter************** */}
      
    </div>
  );
};

export default Home;
