import React, { useEffect, useState } from "react";
import $ from "jquery";
import "jquery-ui/ui/widgets/autocomplete"; // Include the specific jQuery UI module you need
import "./SuppGestionSms.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Include Bootstrap styles
import { Link, useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from "react-redux";



import { clearAdherant, findAdherant } from "../../../Redux/Actions/adherantAction";
import { getappros } from "../../../Redux/Actions/userAction";

const SuppGestionSms = () => {
  const user=useSelector(state=>state.userReducer.user)
  const dispatch = useDispatch();
  const [cin, setCin] = useState({});
  const navigate=useNavigate()

  const handleChange=(e)=>{
    setCin({...cin,[e.target.name]:e.target.value})
  }
  useEffect(() => {
    // Your jQuery code here
    $(".veen .rgstr-btn button").click(function () {
      $(".veen .wrapper-fx").addClass("move");
      $(".body").css("background", "linear-gradient(90deg, hsla(205, 46%, 10%, 1) 0%, hsla(191, 28%, 23%, 1) 50%, hsla(207, 41%, 27%, 1) 100%)");
      $(".veen .login-btn button").removeClass("active");
      $(this).addClass("active");
    });

    $(".veen .login-btn button").click(function () {
      $(".veen .wrapper-fx").removeClass("move");
      $(".body").css("background", "linear-gradient(90deg, hsla(207, 41%, 27%, 1) 100%),hsla(191, 28%, 23%, 1) 50%, hsla(205, 46%, 10%, 1) 100%)");
      $(".veen .rgstr-btn button").removeClass("active");
      $(this).addClass("active");
    });
  }, []);

 useEffect(() => {
   dispatch(clearAdherant());
 },)
 const dependency=user.dependency

  const handleClick =async () => {
   dispatch(findAdherant(cin))
  navigate ('/SuppSmsOrge')
  };
  const handleajout=async()=>{
    dispatch(getappros(dependency))
  }
  

  return (
    <div className="body-f">
      <div className="veen">
        <div className="login-btn splits">
          <p>تريد  ارسال اكياس شعير تكميلية ؟</p>
          <button className="active">اختيار</button>
        </div>
        <div className="rgstr-btn splits">
          <p>تريد  ارسال اكياس سداري تكميلية ؟</p>
          <button>اختيار</button>
        </div>
        <div className="wrapper-fx">
          <form id="login" tabIndex="500">
            <h3> رسائل شعير تكميلية </h3>
            <div className="submit">
              <Link to="/SuppSmsOrge">
                <button className="dark-d" style={{ marginTop: '50px' }} onClick={handleajout}>ارسال</button>
              </Link>
            </div>
          </form>
          <form id="register" tabIndex="502">
            <h3> رسائل سداري تكميلية</h3>
            <Link to="/SuppSmsCedra">
            <button className="dark-d" style={{ marginTop: '50px' }} onClick={handleajout}>ارسال</button>
          </Link>
           
              
               
            
          </form>
        </div>
      </div>
    </div>
  );      
};

export default SuppGestionSms;
