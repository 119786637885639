import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { getStatisticappros } from '../../../Redux/Actions/userAction';
import ModalApproStat from './ModalApproStat';
import axios from 'axios';
import Count from './Count';

const ApproStat = () => {
  const dispatch=useDispatch()
    const [validated, setValidated] = useState(false);
    const user=useSelector(state=>state.userReducer.user)
    const approlist = useSelector((state) => state.userReducer.statAppro);
    const [dependency,setDependency]=useState(user.dependency)

    
   useEffect(()=>{
    if(dependency){
      dispatch(getStatisticappros({dependency:dependency}))
    }
   },[dependency])

   
  return (
    <div style={{marginBottom:'200px'}}>
    <Form
    noValidate
    validated={validated}
    className="p-4  rounded  text-center"
    style={{ backgroundColor: "#f8f9fa" }}
  >
    <h1 style={{ marginTop: "100px", marginBottom: "105px" }}>
      فضاء احصائيات النيابات
    </h1>
    
    <Form.Group style={{ marginTop: "70px" }} as={Row} className="mb-3">
    <Form.Label column md={2} className="text-end order-md-2">
      الاتحادات المحلية 
    </Form.Label>
    <Col md={{ span: 3, offset: 6 }} className="order-md-1">
    {user?.typeofaccount === "delegation" || user?.typeofaccount === "Unionlocal"? <Form.Select className="text-end" placeholder="ــ اختيار ــ" value={user.dependency}>
        <option>ــ اختيار ــ </option>
            <option value="1">بنقردان</option>
            <option value="2">بني خداش</option>
            <option value="3"> جربة ميدون</option>
            <option value="4"> جربة أجيم</option>
            <option value="5">سيدي مخلوف</option>
            <option value="6"> جربة حومة السوق</option>
            <option value="7"> جرجيس</option>
            <option value="8">مدنين الجنوبية</option>
            <option value="9">مدنيـن الشمالية</option>
      </Form.Select>:<Form.Select className="text-end" placeholder="ــ اختيار ــ" onChange={(e)=>setDependency(e.target.value)}>
        <option>ــ اختيار ــ </option>
            <option value="1">بنقردان</option>
            <option value="2">بني خداش</option>
            <option value="3"> جربة ميدون</option>
            <option value="4"> جربة أجيم</option>
            <option value="5">سيدي مخلوف</option>
            <option value="6"> جربة حومة السوق</option>
            <option value="7"> جرجيس</option>
            <option value="8">مدنين الجنوبية</option>
            <option value="9">مدنيـن الشمالية</option>
      </Form.Select>}
    </Col>
  </Form.Group>

  {/* table   */}
  <div style={{ height: '400px', overflowY: 'auto', marginTop: '100px' }}>
  <Table
    striped
    bordered
    hover
    variant="dark"
    responsive="md"
    dir="rtl"
    style={{ marginTop: '10px' }}
  >
    <thead>
     
      <tr>
      <th></th>
        <th>بريد إلكتروني</th>
        <th>اسم تاجر الأعلاف</th>
        <th>عدد شاحنات الشعير</th>
        <th>عدد شاحنات السداري</th>
        <th>  كمية الشعير الموزعة</th>
        <th>  كمية السداري الموزعة</th>
        <th>  كمية الشعير المتبقية </th>
        <th>  كمية السداري المتبقية</th>
        <th>عدد شاحنات الشعير الموزعة</th>
        <th>عدد شاحنات السداري الموزعة</th>
        <th>عدد الفلاحين</th>
        <th> احصائيات</th>



      </tr>
    </thead>
    <tbody>
  
         {approlist?.map((el,index) =>
           <tr key={el._id}>
           <td style={{ width: '50px' }}>{index + 1}</td>
           <td>{el.email}</td>
           <td>{el.name}</td>
           <td>{el.numberofcamionO}</td>
           <td>{el.numberofcamionS}</td>
           <td>{el.qtOTotal}</td>
           <td>{el.qtCTotal}</td>
           <td>{el.restO}</td>
           <td>{el.restC}</td>
           <td>{el.numCamionO}</td>
           <td>{el.numCamionC}</td>
           <td><Count proxy={el.name}/></td>
           <td><ModalApproStat el={el}/></td>
          

         </tr>)}
        
    </tbody>
  </Table>
</div>

    </Form>
    </div>
  )
}

export default ApproStat
