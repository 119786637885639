import React, { useEffect, useState } from "react";
import $ from "jquery";
import "jquery-ui/ui/widgets/autocomplete"; // Include the specific jQuery UI module you need
import "./Approvisionnement.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Include Bootstrap styles
import { Link, useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';

import { useDispatch } from "react-redux";
import { clearAppro, findappro } from "../../Redux/Actions/userAction";




const Approvisionnement = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState({});
  const navigate=useNavigate()

  const handleChange=(e)=>{
    setEmail({...email,[e.target.name]:e.target.value})
  }
  useEffect(() => {
    // Your jQuery code here
    $(".veen .rgstr-btn button").click(function () {
      $(".veen .wrapper").addClass("move");
      $(".body").css("background", " linear-gradient(90deg, hsla(56, 97%, 56%, 1) 5%, hsla(134, 87%, 42%, 1) 86%)");
      $(".veen .login-btn button").removeClass("active");
      $(this).addClass("active");
    });   

    $(".veen .login-btn button").click(function () {
      $(".veen .wrapper").removeClass("move");
      $(".body").css("background", "linear-gradient(90deg, hsla(134, 87%, 42%, 1) 5%, hsla(56, 97%, 56%, 1) 86%)");
      $(".veen .rgstr-btn button").removeClass("active");
      $(this).addClass("active");
    });
  }, []);
  useEffect(() => {
    dispatch(clearAppro())
  }, )
  
  const handleClick =async () => {
    dispatch(findappro(email))
   navigate ('/ApprovisionnementModifier')
   
   };
  return (
    <div className="body-Approv">
      <div className="veen">
        <div className="login-btn splits">
          <p>تريد إضافة تاجر أعلاف ؟</p>
          <button className="active">إضافة</button>
        </div>
        <div className="rgstr-btn splits">
          <p>تريد تعديل تاجر أعلاف ؟</p>
          <button>تعديل</button>
        </div>
        <div className="wrapper">
          <form id="login" tabIndex="500">
            <h3>إضافة تاجر أعلاف </h3>
            <div className="submit">
            <Link  to="/ApprovisionnementAjouter"> <button className="dark"  style={{marginTop:'50px'}}>إضافة</button>  </Link> 
            </div>
          </form>

          <form id="register" tabIndex="502">
          <h3>تعديل حساب تاجر الأعلاف</h3>
            <Form.Control  type="search" placeholder="إبحث هنا" className="me-2 text-end" aria-label="Search" name="email" onChange={(e)=>handleChange(e)} />
            <div className="submit">
            <button className="dark"  onClick={async () => handleClick()} >تعديل</button>  
            </div>
            
          </form>
          
        </div>
      </div>
    </div>
  );
};

export default Approvisionnement; 
