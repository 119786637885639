import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { deleteAppro, editAppro } from "../../Redux/Actions/userAction";

const ApprovisionnementModifier = () => {
  
 const loadUser=useSelector(state=>state.userReducer.loadUser)
 const foundAppro=useSelector(state=>state.userReducer.foundAppro)
 const navigate=useNavigate()
 const dispatch=useDispatch()
  const [appro,setAppro]=useState({})
  
useEffect(()=>{
  
  if(loadUser==false){
    if(foundAppro==null){
      
      navigate("/Approvisionnement")
    }
  }
},[foundAppro,loadUser])
const handleChange=(e)=>{
  setAppro({...appro,[e.target.name]:e.target.value})
 }
 const handleClick=async()=>{
  await dispatch(editAppro(foundAppro._id,appro))
  navigate("/Approvisionnement")
 }
 const handleDelete=async()=>{
  await dispatch(deleteAppro(foundAppro._id))
  navigate("/Approvisionnement")
  handleClose();
  
 }
 
   // modal delete button
   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
 
  return (
    <div>
      <Form
        
        className="p-4 border rounded shadow text-center"
        style={{ backgroundColor: "#f8f9fa" }}
      >
      <h1 style={{ marginTop: "50px", color:" hsla(82, 96%, 57%, 1)" }}> فضاء النيابات</h1>
      <br />
      <h2
        style={{
          marginTop: "50px",
          marginBottom: "80px",
          color:"hsla(217, 100%, 50%, 1)",
        }}
      >
        تعديل تاجر الاعلاف
      </h2>
        {/* label  1 */}

        <Form.Group style={{ marginTop: "120px" }} as={Row} className="mb-4">
          <Form.Label column md={1} className="text-end order-md-2">
          بريد إلكتروني 
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Control
            name="email"
            onChange={(e)=>handleChange(e)}
              type="text"
              placeholder={foundAppro?.email}
              className="text-end"
              
            />
            <Form.Control.Feedback type="invalid">
              يجب تعمير هذه الخانة
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        {/* label 2  */}
       

        {/* label 3  */}

        <Form.Group as={Row} className="mb-4">
          <Form.Label column md={1} className="text-end order-md-2">
            اسم المستخدم
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Control
              onChange={(e)=>handleChange(e)}
              name="name"
              type="text"
              placeholder={foundAppro?.name}
              className="text-end"
              
            />
            <Form.Control.Feedback type="invalid">
              يجب تعمير هذه الخانة
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        {/* label 4  */}
        <Form.Group as={Row} className="mb-4">
          <Form.Label column md={1} className="text-end order-md-2">
            كلمة السر
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Control
              onChange={(e)=>handleChange(e)}
              type="text"
              name="password"
              placeholder="كلمة السر"
              className="text-end"
              
            />
            <Form.Control.Feedback type="invalid">
              يجب تعمير هذه الخانة
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      {/* label 5 */}
      <Form.Group as={Row} className="mb-4">
      <Form.Label column md={1} className="text-end order-md-2">
        المعتمدية
      </Form.Label>
      <Col md={{ span: 3, offset: 6 }} className="order-md-1">
        <Form.Select 
        className="text-end"
           name="dependency"
          
          value={foundAppro?.dependency}
          >
          
          <option>ــ اختيار ــ </option>
          <option value={1}>بنقردان</option>
          <option value={2}>بني خداش</option>
          <option value={3}> جربة ميدون</option>
          <option value={4}> جربة أجيم</option>
          <option value={5}>سيدي مخلوف</option>
          <option value={6}> جربة حومة السوق</option>
          <option value={7}> جرجيس</option>
          <option value={8}>مدنين الجنوبية</option>
          <option value={9}>مدنيـن الشمالية</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          يجب اختيار خانة
        </Form.Control.Feedback>
      </Col>
    </Form.Group>

        {/* sapce 150 px */}

        {/* label 5*/}

        <Form.Group as={Row} style={{ marginTop: "100px" }}>
          <Form.Label column md={1} className="text-end order-md-2">
            عدد شاحنات الشعير
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Control
              onChange={(e)=>handleChange(e)}
              name="numberofcamionO"
              min={0}
                type="number"
              pattern="[0-9]{0-4}"
              placeholder={foundAppro?.numberofcamionO}
              className="text-end"
            />
            <Form.Control.Feedback type="invalid">
              يجب تعمير هذه الخانة
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        {/* label 7 */}

        <Form.Group style={{  marginBottom:'40px'}} as={Row} className="mt-3">
          <Form.Label column md={1} className="text-end order-md-2">
            عدد شاحنات السداري
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Control
              onChange={(e)=>handleChange(e)}
              name="numberofcamionS"
              min={0}
                type="number"
              pattern="[0-9]{0-4}"
              placeholder={foundAppro?.numberofcamionS}

              className="text-end"
              
            />
            <Form.Control.Feedback type="invalid">
              يجب تعمير هذه الخانة
            </Form.Control.Feedback>
          </Col>
        </Form.Group>


        {/* sapce 150 px */}
        {/* label 8 */}

      
      <Button  variant="outline-danger"  onClick={handleShow}>
        {" "}
         حذف تاجر الاعلاف
      </Button>
      <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title dir="rtl" >تأكيد حذف تاجر الاعلاف</Modal.Title>
      </Modal.Header>
      <Modal.Body dir="rtl">هل تريد حقا حذف  تاجر الاعلاف...؟</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          الغاء 
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          تأكيد الحذف
        </Button>
      </Modal.Footer>
    </Modal>

      <Button
        style={{ marginLeft: "40px" }}
        variant="outline-success"
        onClick={handleClick}
      >
        تعديل النيابة
      </Button>
      </Form>
    </div>
  );
};


export default ApprovisionnementModifier;
