import React, { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { addAdherant } from "../../Redux/Actions/adherantAction";
import { toast } from "react-toastify";
import Loading from "../../Components/loading/Loading";

const AdherantAjouter = () => {
  const date= new Date()
  const user=useSelector(state=>state.userReducer.user)
  const loadUser=useSelector(state=>state.userReducer.loadUser)
  const approlist=useSelector(state=>state.userReducer.approlist)
  const [adherant, setAdherant] = useState({dependency:user.dependency,dateInscrit:date})
  const dispatch=useDispatch()
  const navigate=useNavigate()
  console.log(adherant)
  const handleChange=(e)=>{
    setAdherant({...adherant,[e.target.name]:e.target.value})
  }
  

  const handleClick = async () => {
    
      // Additional validation checks
      if (!adherant.cin || !/^[01]\d{7}$/.test(adherant.cin)) {
        toast.error("رقم بطاقة التعريف غير صحيح!");}
      else{
      
        await dispatch(addAdherant(adherant));
        
      }
    } ;
    

  return ( 
    <div>
      {loadUser==true?<Loading/>:<div>{/* label  1 */}
      <Form
        
        className="p-4 border rounded shadow text-center"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <h1 style={{ marginTop: "50px" }}> فضاء الفلاح</h1>
        <br />
        <h2
          style={{
            marginTop: "50px",
            marginBottom: "80px",
            color: " hsla(101, 66%, 24%, 1)",
          }}
        >
          اضافة فلاح
        </h2>
        <Form.Group as={Row} className="mb-3" style={{ marginTop: "10 0px" }}>
          <Form.Label column md={1} className="text-end order-md-2">
            رقم بطاقة التعريف الوطنية{" "}
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            
              <Form.Control
              name="cin"
                type="tel"
                pattern="[01][0-9]{7}"
                maxLength="8"
                placeholder="رقم بطاقة التعريف الوطنية "
                
                className="text-end"
                onChange={(e)=>handleChange(e)}
              />
              <Form.Control.Feedback type="invalid">
                يرجى كتابة رقم بطاقة التعريف الوطنية
              </Form.Control.Feedback>
            
          </Col>
        </Form.Group>

        {/* label 2  */}
        <Form.Group as={Row} className="mb-4">
          <Form.Label column md={1} className="text-end order-md-2">
            الاسم
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Control
            name="name"
              required
              type="text"
              placeholder="الاسم"
              className="text-end"
              onChange={(e)=>handleChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              يجب تعمير هذه الخانة
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        {/* label 3  */}
        <Form.Group as={Row} className="mb-3">
          <Form.Label column md={1} className="text-end order-md-2">
            اللقب
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Control
            name="lastname"
              required
              type="text"
              placeholder="اللقب"
              className="text-end"
              onChange={(e)=>handleChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              يجب تعمير هذه الخانة
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        {/* label 3 Added */}
        <Form.Group as={Row} className="mb-4">
          <Form.Label column md={1} className="text-end order-md-2">
            إسم الأب
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Control
            name="fathername"
              required
              type="text"
              placeholder="الاسم"
              className="text-end"
              onChange={(e)=>handleChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              يجب تعمير هذه الخانة
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        {/* label 4  */}

        <Form.Group as={Row} className="mb-3">
          <Form.Label column md={1} className="text-end order-md-2">
            الهاتف{" "}
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1" style={{display:"flex",flexDirection:"row"}}>
            
              <Button id="phone-addon">+216</Button>
              <Form.Control
              name="phone"
                type="tel"
                pattern="[0-9]{8}"
                maxLength="8"
                placeholder="رقم الهاتف"
                aria-describedby="phone-addon"
                
                className="text-end"
                onChange={(e)=>handleChange(e)}
              />
              <Form.Control.Feedback type="invalid">
                يرجى كتابة ثمانية أرقام من رقم الهاتف
              </Form.Control.Feedback>
            
          </Col>
        </Form.Group>

        {/* label 5*/}

        <Form.Group as={Row} className="mb-4">
          <Form.Label column md={1} className="text-end order-md-2">
            المعتمدية
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Select className="text-end" placeholder="ــ اختيار ــ" value={user.dependency} >
              <option>ــ اختيار ــ </option>
              <option value="1">بنقردان</option>
              <option value="2">بني خداش</option>
              <option value="3"> جربة ميدون</option>
              <option value="4"> جربة أجيم</option>
              <option value="5">سيدي مخلوف</option>
              <option value="6"> جربة حومة السوق</option>
              <option value="7"> جرجيس</option>
              <option value="8">مدنين الجنوبية</option>
              <option value="9">مدنيـن الشمالية</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              يجب اختيار خانة
            </Form.Control.Feedback>
          </Col>
        </Form.Group>


        <Form.Group as={Row} className="mb-4">
        <Form.Label column md={1} className="text-end order-md-2">
          تاجر اعلاف
        </Form.Label>
        <Col md={{ span: 3, offset: 6 }} className="order-md-1">
          <Form.Select className="text-end" placeholder="ــ اختيار ــ" name="proxy" onChange={(e)=>handleChange(e)}>
            <option>ــ اختيار ــ </option>
            {approlist.map(el=><option key={el._id} value={el.name}>{el.name}</option>)}
         
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            يجب اختيار خانة
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
        {/* sapce 150 px */}

        {/* label 6*/}

        

       

        {/* sapce 150 px */}
        {/* label 8 */}
        <Row>
          <Form.Group as={Row} className="mb-3" style={{ marginTop: "150px" }}>
            <Form.Label column md={1} className="text-end order-md-2">
            ضان
            </Form.Label>
            <Col md={{ span: 3, offset: 6 }} className="order-md-1">
              <Form.Control
              name="mouton"
              min={0}
                type="number"
                placeholder=" 0 "
                className="text-end"
                onChange={(e)=>handleChange(e)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column md={1} className="text-end order-md-2">
              الماعز
            </Form.Label>
            <Col md={{ span: 3, offset: 6 }} className="order-md-1">
              <Form.Control
              name="chevre"
              min={0}
              type="number"
                placeholder=" 0 "
                className="text-end"
                onChange={(e)=>handleChange(e)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column md={1} className="text-end order-md-2">
            خيول
            </Form.Label>
            <Col md={{ span: 3, offset: 6 }} className="order-md-1">
              <Form.Control
              name="cheval"
              min={0}
              type="number"
                placeholder=" 0 "
                className="text-end"
                onChange={(e)=>handleChange(e)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column md={1} className="text-end order-md-2">
            ابل
            </Form.Label>
            <Col md={{ span: 3, offset: 6 }} className="order-md-1">
              <Form.Control
              name="chameau"
                min={0}
              type="number"
                placeholder=" 0 "
                className="text-end"
                onChange={(e)=>handleChange(e)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column md={1} className="text-end order-md-2">
            ابقار
            </Form.Label>
            <Col md={{ span: 3, offset: 6 }} className="order-md-1">
              <Form.Control
              name="vache"
              min={0}
              type="number"
                placeholder=" 0 "
                className="text-end"
                onChange={(e)=>handleChange(e)}
              />
            </Col>
          </Form.Group>
        </Row>
        <Button variant="outline-success"  onClick={async () => handleClick()}>
          {" "}
          اضافة الفلاح
        </Button>
      </Form> </div>}
    </div> 
  );
};

export default AdherantAjouter
