// Footer.js (React Component)

import React from "react";
import "./Footer.css"; // Don't forget to import your CSS file
import { Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.userReducer.user);
  
  return (
    <footer className="site-footer">
      <div style={{}} className="container1">
        <div className="row">
          <div className="col-xs-6 col-md-3">
            <h6>روابط مختصرة</h6>
            <ul className="footer-links">

            

              {user?.typeofaccount == "Admin" || user?.typeofaccount=="Unionlocal" || user?.typeofaccount=="Approvisionnement" ? <Nav.Link onClick={() => navigate("/*")}>تأكيد الاستلام</Nav.Link>: null}
              {user?.typeofaccount == "Admin" || user?.typeofaccount=="Unionlocal"? <Nav.Link onClick={() => navigate("/*")}>الإحصائيات</Nav.Link>: null}
              {user?.typeofaccount == "Admin" || user?.typeofaccount=="Unionlocal"? <Nav.Link onClick={() => navigate("/Gestionsms")}>فضاء الأرساليات</Nav.Link>: null}
              {user?.typeofaccount == "Admin" || user?.typeofaccount=="Unionlocal"? (
                <Nav.Link onClick={() => navigate("/Approvisionnement")}>
                  فضاء النيابات
                </Nav.Link>
              ) : null}
             {user?.typeofaccount == "Admin" || user?.typeofaccount=="Unionlocal"?  <Nav.Link onClick={() => navigate("/Adherant")}>فضاء الفلاحين</Nav.Link>: null}
               <Nav.Link onClick={() => navigate("/")}>الرئيسية</Nav.Link>
               </ul>
          </div>

          {/* about us */}
          <div className="col-sm-12 col-md-6">
            <h6>من نحن ؟</h6>
            <p className="text-justify">
              مرحبًا بك في منظومة الاعلاف الذكية ، التي تربط بين الفلاحين وموزعي
              الأعلاف بسلاسة. نحن نفخر بتمكين الفلاحين من المعلومات الكافية
              والوقت المناسب حول توفر الأعلاف، من خلال إرساليات يتم إرسالها
              مباشرة إلى هواتفهم. يستخدم نظامنا المبتكر رمز فريد لكل فلاح يمكنه
              من الحصول على الكمية المناسبة حسب عدد المواشي الخاصة به
            </p>
          </div>
        </div>
        <hr />
      </div>
      <div className="container1">
        <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className="copyright-text">
              حقوق النشر &copy; 2023 جميع الحقوق محفوظة لدى منظومة الاعلاف
              الذكية و الاتحاد التونسي للفلاحة و الصيد البحري.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
